@tailwind base;
@tailwind components;
@tailwind utilities;

/** custom CSS **/
.th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.td {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}

.td-main {
  @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900;
}

.slick-slide > div {
  margin: 0 10px;
}

.mantine-Radio-radio {
  --tw-ring-color: #dc2626 !important;
  color: #dc2626 !important;
}
